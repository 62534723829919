import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/pages/Home/Home.vue';
import NotFound from '@/pages/404/404.vue';
import PrivacyPolicy from '@/pages/PrivacyPolicy/PrivacyPolicy.vue';
import TermsOfService from '@/pages/TermsOfService/TermsOfService.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms_of_service',
    name: 'terms_of_service',
    component: TermsOfService,
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  },
];

const router = new VueRouter({
  base: '/desktop',
  routes,
  mode: 'history'
});

// 登录态控制路由规则
router.beforeEach((to, from, next) => {
  next();
})

export default router;
