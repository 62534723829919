import mixins from '@/mixins';

export default {
  name: 'TermsOfService',

  mixins: [mixins],

  data() {
    return {}
  },
}
